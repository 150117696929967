.footer {
    width: 100%;
}

.footer ul {
    display: flex;
    list-style: none;
    flex-direction: row;
    gap: 5rem;
    justify-content: center;
    margin: 0 auto 2% auto;
}

.footer a {
    text-decoration: none;
    color: white;
    font-size: 14px;
}

@media only screen and (max-width: 1100px) {
    .footer {
        text-align: center;
    }
    
    .footer ul {
        gap: 1rem;
        flex-direction: column;
        margin-bottom: 2%;
    }
}