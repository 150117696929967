.button {
    font: inherit;
    border: 1px solid #fff;
    background: hsl(220,87%,6%);
    color: white;
    padding: 0.75rem 3.5rem;
    cursor: pointer;
    font-size: 1.15rem;
    border-radius: 30px;
}

.button:hover,
.button:active {
    background: hsl(220,87%,6%);
    border-color: hsl(220,87%,6%);
}

.button:focus {
    outline: none;
}

button:disabled,
button:focus:disabled,
button:hover:disabled,
button:active:disabled {
    background: #ccc;
    border-color: #ccc;
    color: #666666;
    cursor: not-allowed;
}