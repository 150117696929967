.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    flex-wrap: wrap;
    justify-content: center;
}

/**Container vid/text**/
.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
}

/***Vid Container ***/
.vidContainer {
    width: 50%;
}

.vidContainer img {
    width: 100%;
}

.text {
    display: none;
}

/***Text Container***/
.textWrap {
    width: 50%;
}

.textWrap p {
    text-align: justify;
}

.animation {
    display: block;
    animation: textAni 3s;
}


@keyframes textAni {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}

/**Arrow**/
.arrowGrid {
    top:190% !important;
}
@media only screen and (max-width: 1100px) {
    .wrapper {
        width: 100%;
        flex-direction: column-reverse;
        padding: 0;
        gap: 5rem;
        height: auto;
    }

    .vidContainer, .textWrap {
        width: 90%;
        margin:0 auto;
    }
}