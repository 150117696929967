.formWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.textAreaW {
    align-items: center;
    flex-direction: row;
}

.sizeInput {
    width: 304.52px;
    flex: 3 1;
    font: inherit;
    padding: 0.35rem 0.35rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    height: 100px;
    margin-bottom: 1rem;
}

.actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.actions button {
    cursor: pointer;
    font: inherit;
    color: white;
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
}

.actions button:hover {
    background-color: black;
    border-color:black;
}

.actions .toggle {
    margin-top: 1rem;
    background-color: transparent;
    color: black;
    border: none;
    padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
    background-color: transparent;
    color: black;
}

.disable {
    cursor: not-allowed;
    pointer-events: none;
}

.messages {
    width: 70%;
}