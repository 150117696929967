.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
}

.modal {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    color:black;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
}



@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-3rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 700px) {
    .modal {
        width: auto;
        padding: 0;
        text-align: center;
    }
 }