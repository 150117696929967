.header {
    color: white;
    margin: 0 auto;
    overflow: hidden;
}

.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    margin-top: 1%;
}

.langMenu {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width: 20%;
    position: absolute;
}
.langMenu div {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.langMenu div figure {
    width: 20px;
    height: auto;
}
.selected {
    font-weight: 900;
    font-size: 18px;
}
.langMenu div figure img {
    width: 100%;
}

.langMenu a {
    border: 1px black;
    text-decoration: none;
    color: white;
}

/* .langMenu a:first-child {
    font-weight: 900;
    font-size: 18px;
} */
/* .langMenu a:not(:first-child) {
    display: none;
}

.langMenu:hover a {
    display: block !important;
} */

.user {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.imp {
    margin: 0 auto;
    width: 80%;
    margin-top: 10%;
}


.DpWrap {
    margin-top: 10%;

}

.DpWrap h1, .Dp {
    margin-bottom: 5%;
}

.DpWrap p {
    margin-bottom: 1%;
}

.Dp h2 {
    margin-bottom: 2%;
}

@media only screen and (max-width: 1100px) {
    header {
        width: 100%;
        position: sticky; 
        top: 0;
    }

    .langMenu {
        position: inherit;
        width: 80%;
    }
    .wrapper {
        width: 100%;
        background-color: hsl(219, 87%, 6%);
        padding:30px;
        margin:0;
    }

}