.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #fff;

}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    display: none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    width: 250px !important;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    /* background-color: hsl(219, 87%, 6%); */
    padding: 2.5em 1.5em 0;
    font-size: 16px;
    line-height: 18.72px;
    margin: 0;
    padding: 0;
    overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
    margin-bottom: 15%;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.center-a {
    text-align: center;
}

.header {
    padding: 10px 16px;
    background: #555;
    color: #f1f1f1;
  }
  
  .content {
    padding: 16px;
  }
  
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  .sticky + .content {
    padding-top: 102px;
  }

@media only screen and (max-width: 1100px) {
    .bm-menu {

        background-color: hsl(219, 87%, 6%);
    }

    .bm-item>* {
        margin: 10% auto;
    }


}