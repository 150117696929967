.wrap {
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
}

.container div {
    width: 45%;
}

.container div img {
    width: 100%;
}

.container p {
    text-align: center;
}