
.wrapper {
    height: 100vh;
}
.wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 10%;
}

.wrapper h1 {
    margin-top: 5%;
}

.wrapperVid {
    display: flex;
    height: 100vh;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.textBlock  {
    text-align: justify !important;
}
.vidContainer {
    width: 50%;
    height: auto;
}

.vidContainer img {
    width: 100%;
}

.vidContainer video {
    margin-bottom: 10%;
}

.vidContainer2 {
    width: 55%;
    height: auto;
}

.textWrap {
    width: 50%;
}

.reSizeText {
    width: 40%;
}

.textWrap>* {
    margin-bottom: 5%;
}

.textContainer {
    display: flex;
}

.textContainer div {
    width: 50%;
}
.textContainer div p {
    font-size: 14px;
    line-height: 20.7px;
    margin-bottom: 5%;
}

.textContainer div p:first-child {
    font-size: 20px;
    margin-bottom: 5%;
}

.text {
    width: 50%;
    display: none;
}

.textWrap p {
    text-align: center;
}

.animation {
    display: block;
    animation: textAni 3s;
}

.borderP {
    border: 1px solid white;
    padding: 1rem;
}

.borderP p {
    font-weight: bold;
}

.manualWrapper a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.manualWrapper img {
    width: 52px;
}

.menuItem {
    color:white;
}

@keyframes textAni {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 1100px) {

    .wrapper {
        width: 90%;
        margin:0 auto;
        height: auto;
    }

    .wrap {
        flex-direction: column-reverse;
    }

    .wrapperVid {
        width: 90%;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 0 auto;
        margin-top:10%;
    }

    .textWrap {
        width: 100%;
    }

    .vidContainer, .vidContainer2 {
        width: 100%;
        margin: 0;
    }

    .mrgBtn {
        margin-bottom: 10%;
    }
}