* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 100%;
}

body {
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: hsl(219, 87%, 6%);
  color: white;
}

#page-wrap {
  width: 75%;
  margin: 0 auto;
}

h1 {
  margin-bottom: 8%;
}

h2,p{
  margin-bottom: 5%;
}

h1, h2, p {
  font-weight: 400;
}

h1 {
  font-size: 42px;
  line-height: 63px;
}

h2 {
  font-size: 24px;
  line-height: 36px;
}

p {
  font-size: 16px;
  line-height: 24px;
}



@media only screen and (max-width: 600px) {
  #page-wrap {
    width: 100%;
  }

  h1 {
    font-size: 21px;
    line-height: 31.5px;
  }

  h2 {
    font-size: 18px;
    line-height: 27px;
  }

  p {
    font-size: 14px;
    line-height: 21px;
  }
}