.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    padding: 0 !important;
    margin-top: 5%;
    align-content: center;
    align-items: center;
}

.mapContainer {
    cursor: pointer;
    height: 450px;
    position: relative;
    max-width: 600px;
}

.mapContainer iframe {
    border: 0;
    text-align: center;
    margin-right: 30%;
}

.mapOverlay {
    align-items: center;
    background-color: hsla(0, 0%, 7%, .8);
    bottom: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
}

@media only screen and (max-width: 1100px) {
    .wrapper {
        height: auto;
    }

    .mapContainer {
        margin: 5% 0;
    }
    
    .mapContainer iframe {
        width: 300px;
    }
}

@media only screen and (max-width: 600px) {
    .formContainer {
        flex-direction: column;
    }
}