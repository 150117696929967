.headerW {
    height: 5vh !important;
    margin: 2% auto !important;
    overflow: initial !important;

}

.wrap {
    margin: 5%;
}


.wrap div {
    margin-bottom: 1% !important;
}
.container {
    margin-top: 5%;
    height: 80vh;
}

.wrap div h1, .wrap div p, .wrap div h2 {
    margin: 2rem !important;
}

.wrap div h1:first-child, .wrap div p:first-child, .wrap div h2:first-child {
    margin: 2% 0;
}

.extraSpace {
    margin: 3% 0;
}
p {
    margin: auto;
}

.container > * {
    margin-bottom: 1%;
}


@media only screen and (max-width: 1100px) {
    .wrap div {
        margin-top: 20% !important;
    }
}

.legalsContainer {
    padding: 0 12rem;
    padding-top: 50px;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 100px;
    font-size: larger;
    text-align: left;
    color: white;
  }

 