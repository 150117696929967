.wrap {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 3rem;
}



.btnWrap {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.btn {
    background-color: hsl(219, 87%, 6%);
    color: white;
}

.btn:hover {
    background-color: hsl(219, 87%, 6%);
}

@media only screen and (max-width: 700px) {
    .wrap {
        width: 85%;
        margin: 5% auto;
    }
   .btn {
    width: 70%;
    text-align: center;
    height: auto;
    text-align: center;
   }
}