.poster {
    margin: auto 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.poster img {
    width: 100%;
}
.wrap {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 2%;
}

.container div {
    width: 50%;
}

.container div img {
    width: 100%;
}

.pRight p {
    font-size: 14px;
    margin-bottom: 2%;
}

.arrowP {
    top:190%
}