.wrapper {
    height: 100vh;
}
.wrap {
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
}


.container {
    text-align: left;
}

.container h4 {
    margin-bottom: 1rem;
}

.container div {
    margin-left: 0.5rem;
}

.text {
    width: 40%;
    padding: 5em;
    position: absolute;
    animation: fadeIn 5s;
    font-weight: 500;
    
}

.text a {
    text-decoration: none;
}
.text a:visited {
    color:white;
}

.dl {
    font-weight: 600;
    text-decoration: none; 
    color: inherit;
    background: none; 
    border: none; 
}
.text p {
    text-align: justify;
}

.linkA, .text a, .dl {
    color: white;
}

.linkA:visited {
    text-decoration: none; 

}



@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}

.pSpace p{
    margin-bottom: 1% !important;
}
/* .text>* {
    margin-bottom: 5%;
} */

.canvasWrap {
    width: 100%;
    overflow: hidden;
}

.canvas {
    margin-left: 20%;
    width: 100%;
    height: 100vh;
}

@media only screen and (max-width: 1100px) {
    .text {
        width: 60%;
        margin: 30% auto;
        padding: 0;
        position: inherit;
    }

    .canvasWrap {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: -1;
        opacity: 0.4;
    }

    .canvas {
        margin: 0;
    }
}